import { useCookieConfiguration } from '@kaliber/cookie-consent'
import { ButtonTertiary } from '/features/buildingBlocks/Button'

import { routeMap } from '/routeMap'
import { useClientConfig } from '/machinery/ClientConfig'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { cookieConfigurationEvent } from '/machinery/tracking/cookieConfiguration'
import { useReportError } from '/machinery/ReportError'
import { useTranslate } from '/machinery/I18n'

import styles from './CookiePermissions.css'

export function CookiePermissions({ initialConfiguration, layoutClassName = undefined }) {
  const clientConfig = useClientConfig()
  const availablePermissions = clientConfig.cookieConfiguration.availablePermissions
  const reportError = useReportError()
  const formRef = React.useRef(null)
  const { __ } = useTranslate()

  const { acceptAll, rejectAll } = useCookieConfiguration({
    cookieEndpoint: routeMap.api.v1.cookieConfiguration(),
    onChange: pushCookieConsent,
    availablePermissions,
    initialConfiguration,
    onError: reportError
  })

  const name = 'cookie-preference'

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <form ref={formRef} className={styles.options}>
        <Option {...{ name }} label={__`cookie-consent-accept`} value='accept-all' layoutClassName={styles.optionLayout} />
        <Option {...{ name }} label={__`cookie-consent-reject`} value='reject-all' layoutClassName={styles.optionLayout} />
      </form>

      <ButtonTertiary
        onClick={handleSubmit}
        dataX='click-to-confirm-cookie-preferences'
        label={__`confirm-cookie-preferences`}
        layoutClassName={styles.buttonLayout}
      />
    </div>
  )

  function handleSubmit() {
    const { elements } = formRef.current || {}
    const { value } = elements[name] || {}

    if (!value) return

    value === 'accept-all'
      ? acceptAll()
      : rejectAll()
  }
}

function Option({ name, label, value, layoutClassName }) {
  const id = React.useId()

  return (
    <label htmlFor={`x-${id}`} className={cx(styles.componentOption, layoutClassName)}>
      {label}
      <input id={`x-${id}`} type='radio' {...{ name, value }} />
    </label>
  )
}

function pushCookieConsent(configuration) {
  pushToDataLayer(cookieConfigurationEvent(configuration))
}
